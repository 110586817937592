import React from "react";
import { BsGlobe, BsCodeSlash, BsFillPeopleFill } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";

function Info() {
  return (
    <div >
      <div className="max-w-7xl m-auto px-10 md:pb-12 pb-8">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:mt-16 mt-8  gap-6">
          {Datainfos.map((Datainfo) => {
            return (
              <div className="md:px-4 sm:px-20 px-2 py-5">
                <div className="flex  flex-col items-center justify-between w-full">
                  <div className="w-full flex justify-center">
                    {" "}
                    <div>
                      <div className={`${Datainfo.iconColor} text-purple-default w-14 h-14 rounded-full bg-black text-white text-3xl flex justify-center items-center shadow-xl`}>
                        {Datainfo.icon}
                      </div>
                    </div>
                  </div>
                  <div className="pt-4 text-center">
                    <h1 className="boxes-links text-xl font-semibold text-black-light mb-2 ">
                      {Datainfo.title}
                    </h1>
                    <p className=" text-grey-dark text-base font-light ">
                      {Datainfo.desc}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Info;
const Datainfos = [
  {
    id: 1,
    title: "$20",
    desc: "million in savings across hundreds of dealerships",
    icon: <BsGlobe />,
    iconColor:"colorA"
  },
  {
    id: 2,
    title: "500+ ",
    desc: "companies choose DealerSys as their technology consultant",
    icon: <BsCodeSlash />,
    iconColor:"colorC"
  },
  {
    id: 3,
    title: "200+",
    desc: "provider partnerships guaranteeing enhanced support, service and best pricing",
    icon: <FaHandshake />,
    iconColor:"colorG"
  },
  {
    id: 4,
    title: "1000+",
    desc: "fully vetted products managed under one umbrella to give you every option available",
    icon: <BsFillPeopleFill />,
    iconColor:"colorE"
  },
];
