import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Img2 from "./../../images/p2.png";

const Integrations = () => {
  return (
    <div className="w-full px-6 py-12 m-auto max-w-7xl md:px-10">
      <h1 className="md:text-4xl text-2xl text-[#454646] montserrat font-bold md:mb-8 text-center fonbo mb-12 uppercase">
        Integrations
      </h1>
      <p className="text-center Calibri">
        In today’s technological world integrations rule, particularly those
        that offer a better user and client experience by bringing data together
        from multiple systems. We strive to help clients integrate new systems
        with their CRMs, DMS’, schedulers, etc, and are always building new
        connections between platforms with the help of our partners. Here are
        some of our most popular integration partners.
      </p>
      <div className="flex justify-center mt-6">
        <StaticImage src={"./../../images/p2.png"} alt=""  />
      </div>
    </div>
  );
};

export default Integrations;
