import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
function Header() {
  return (
    <div>
      <div className="max-w-7xl m-auto px-6 md:px-8 py-20">
        <div className="grid md:grid-cols-2 grid-cols-1">
          <div className="mt-20 md:mt-7">
            <h1 className="lg:text-5xl sm:text-4xl text-xl  mb-5 md:leading5">
              Customized approach to automotive technology
            </h1>
            <h3 className="lg:text-xl md:text-lg text-base mr-3 mb-5 font-light">
              A one-stop-shop for creating network and communications systems
              that are responsive to your dealership’s needs.
              <br /> We evaluate and offer multifaceted products, services,
              integrations and aggregations that drive successful processes and
              client experiences.
            </h3>
            <p className="lg:text-lg text-sm font-normal ">
              Save the stress for something else
            </p>
            <div className="flex flex-wrap">
              <div className="lg:mt-10 md:mt-16 mt-10">
                <Link to="https://calendly.com/dealersys/general-discussion">
                  <span className="md:px-6 px-4 md:py-5  py-3  mt-10 border-black border-2 rounded-lg transition-all hover:shadow-lg">
                    Schedule a Conversation
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex self-center relative">
            <div className="mt-10 md:m-0">
              <StaticImage
                src="./../../images/herolatest.png"
                alt="dealersys"
                className="rounded-xl z-50"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
