import React from "react";

const Cards = ({ card2 }) => {
  return (
    <div className="max-w-[1170px] mx-auto">
      <div className="px-6 md:px-8 pb-14 ">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 justify-items-center  md:mt-16 mt-8 gap-10">
          {card2.map((item, index) => {
            return (
              <div key={index} className="">
                <Card2
                  index={index}
                  name={item.title}
                  link={item.link}
                  img={item.img}
                  desc={item.desc}
                  src={item.src}
                  btn={item.btn}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Cards;

const Card2 = ({ name, link, img, btn }) => {
  return (
    <div className="mb-20 w-full scale-card2  ">
      <div className=" relative">
        <div className="w-full">
          <img src={img} alt="" className="w-full h-64  object-cover " />
        </div>
        <div className="bg-white md:mb-20 mb-10  absolute z-10 -mt-12 md:w-4/5 w-full box-border py-5">
          <h1 className="text-xs mb-3 uppercase tracking-widest font-normal leading-5 ">
            {name}
          </h1>

          <div>
            <a href={link} target="_blank">
              <span className="text-xl text-black">{btn}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
