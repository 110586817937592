import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Providers = () => {
  return (
    <div id="providers" className="w-full px-6 py-12 m-auto max-w-7xl md:px-10">
      <div className="flex flex-col items-center justify-center w-full">
        <h1 className="md:text-4xl text-2xl text-[#454646] montserrat font-bold md:mb-8 text-center fonbo mb-12 uppercase">
          Providers
        </h1>

        <div className="">
          <p className="text-center Calibri">
            We work with hundreds of individually assessed and chosen providers.
            Aside from lowest pricing guarantees clients also benefit from our
            direct escalation routes to executive level management at each
            provider to ensure the best level of service possible.
            <br />
            <br />
          </p>
        </div>
        <div className="mt-5">
          <Link to="/providers">
            <StaticImage src={"./../../images/Neutral Boho Minimal Moodboard Aesthetic Feminine Photo Collage (10 × 5 cm) (Photo Collage).png"} alt=""  />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Providers;
