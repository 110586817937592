import * as React from "react";
import Index from "./../views/Home/Index";
import Layout from "./../components/Layout";
const IndexPage = ({location}) => {
  return (
    <div>
      {" "}
 <Layout location={location}>
        <Index  location={location}/>
      </Layout>
    </div>
  );
};

export default IndexPage;
