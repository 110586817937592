import React from "react";
import { Link } from "gatsby";
import { FaPhoneAlt, FaCloud } from "react-icons/fa";
import { GrConnect } from "react-icons/gr";
const Cards = () => {
  return (
    <div className="bg-grey-normal py-10">
      <div className=" max-w-7xl mx-auto px-4 ">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4  md:gap-6">
          {data.map((item, index) => {
            return (
              <div key={index}>
                <Card
                  id={item.id}
                  index={index}
                  name={item.name}
                  link={item.link}
                  desc={item.desc}
                  src={item.src}
                  btn={item.btn}
                  icon={item.icons}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Cards;
const Card = ({ id, name, link, desc, src, btn, icon }) => {
  return (
    <div className=" transition-all transform duration-200  relative">
      <div className="absolute min-height gardient1 w-full rounded-3xl z_1"></div>

      <div className="bg-white card1_transform tans min-height px-4 py-10 rounded-3xl shadow-xl">
        <div className="flex">
          <span className="text-purple-default text-3xl"> {icon}</span>
          <h1 className="text-lg mb-8 font-medium flex self-center pl-4">
            {name}
          </h1>
        </div>
        <p className="text-base mb-12 font-normal">{desc}</p>
        <div className="flex justify-between flex-wrap gap-y-10 absolute bottom-8 left-0 right-0 px-4">
          <div className="">
            {id === 3 ? (
              ""
            ) : (
              <a
                href={src}
                className=" text-xs font-semibold tracking-widest border-b-2 border-black"
              >
                LEARN MORE
              </a>
            )}
          </div>
          <div>
            <Link to={link}>
              <span className="font-medium text-sm bg-black text-white px-4 py-3.5 rounded-lg transition-all hover:shadow-lg">
                {btn}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const data = [
  {
    id: 1,
    name: "Unified Communications",
    src: "/contact-us",
    btn: "Free Quote",
    icons: <FaPhoneAlt />,
    link: "/pricing ",
    desc: "Communicate with clients using one platform that integrates calls, texts and videos with your CRM or DMS capturing every single digital interaction.  Know what works and where to invest. ",
  },
  {
    id: 2,
    name: "Network Infrastructure",
    src: "/contact-us",
    btn: "Schedule an Assessment ",
    icons: <GrConnect />,
    link: "https://calendly.com/dealersys/general-discussion",
    desc: "Upgrade your internet services and network infrastructure to make downtime a thing of the past  and improve the quality of your internet connections so that you never miss a thing.  ",
  },
  {
    id: 3,
    name: "Advanced Options ",
    src: "https://www.vonage.com/contact-centers/",
    btn: "Contact a Specialist",
    icons: <FaCloud />,
    link: "/contact-us",
    desc: "Omnichannel solutions, virtual assistants and more.  We handpick the very best in the industry to take your business to the next level. ",
  },
];
