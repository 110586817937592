import React from "react";

import Header from "./Header";
import Info from "./Info";
import Trusted from "./Trusted";
import Speak from "./../../components/Speak";
import Cards1 from "./Cards1";
import Cards2 from "./Cards2";
// import Cards3 from "./Cards3";
import data from "./../../data";
import Solutions from "./Solutions";
import TheDealerSysDifference from "./TheDealerSysDifference";
import Providers from "./Providers";
import Integrations from "./Integrations";
// import BecomePartner from "./BecomePartner";

function Index({location}) {
  return (
    <div>
      <Header />
      <Cards1 card={data.card} />
      <Info />
      <Solutions />
      <Providers />
      <Integrations />
      {/* <BecomePartner /> */}
      <Cards2 card2={data.card2} />
      <Trusted />
      {/* <TheDealerSysDifference /> */}
      {/* <Cards3 card3={data.card3} /> */}
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
