import React from "react";
import { AiFillDatabase, AiOutlineRobot, AiOutlineCheck } from "react-icons/ai";
import { BsHddNetwork } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";
import { GiSatelliteCommunication } from "react-icons/gi";
import { IoMdAnalytics } from "react-icons/io";
import { FaChalkboardTeacher } from "react-icons/fa";

const Solutions = () => {
  return (
    <div id="solutions" className="w-full px-6 py-12 m-auto max-w-7xl md:px-10">
      <div className="flex flex-col items-center justify-center w-full">
        <h1 className="md:text-4xl text-2xl montserrat text-[#454646] font-bold md:mb-12 text-center  mb-12 uppercase">
          SOLUTIONS
        </h1>

        <div className="flex flex-wrap justify-center gap-5">
          {cards.map(({ id, title, icon, iconClr }) => {
            return (
              <div
                key={id}
                className="flex solutionn_card flex-col p-10 border  items-center   transition-all duration-500 hover:-translate-y-3 md:hover:-translate-y-5 rounded-xl max-w-[270px] w-full"
              >
                <div className={`${iconClr} text-3xl text-white solutionn_icon flex justify-center items-center shadow-xl mb-5 w-14 h-14  rounded-full`}>{icon}</div>
                <h3 className="mb-4 text-lg font-semibold text-center text-gray-900">
                  {title}
                </h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Solutions;

const cards = [
  {
    id: 1,
    title: "Data",
    iconClr: "colorA",
    icon: <AiFillDatabase />,
    list: [
      { id: 1, li: "Primary & 2ndary Internet Circuits" },
      { id: 2, li: "Fiber" },
      { id: 3, li: "Coax" },
      { id: 4, li: "DSL" },
      { id: 5, li: "Fixed Wireless" },
      { id: 6, li: "EOC" },
      { id: 7, li: "4G / 5G" },
      { id: 8, li: "Data Transport" },
      { id: 9, li: "MPLS" },
    ],
  },
  {
    id: 2,
    title: "Networking",
    iconClr: "colorB",
    icon: <BsHddNetwork />,
    list: [
      { id: 1, li: "Wi-Fi" },
      { id: 2, li: "Switches and Cabling" },
      { id: 3, li: "MPLS" },
      { id: 4, li: "SD WAN" },
    ],
  },
  {
    id: 3,
    title: "Security",
    iconClr: "colorC",
    icon: <MdOutlineSecurity />,
    list: [
      { id: 1, li: "Firewall" },
      { id: 2, li: "SD WAN" },
      { id: 3, li: "DDOS" },
      { id: 4, li: "Two-Factor Authentication" },
    ],
  },
  {
    id: 4,
    title: "Communications",
    iconClr: "colorD",
    icon: <GiSatelliteCommunication />,
    list: [
      { id: 1, li: "UCaaS (Hosted Phone System)" },
      { id: 2, li: "PBX (Traditional Phone System)" },
      { id: 3, li: "SIP / PRI / Analog Lines" },
      { id: 4, li: "Texting and Chat Platforms" },
      { id: 5, li: "Call Center and Omnichannel Platforms" },
    ],
  },
  {
    id: 5,
    title: "Analytics and Reporting",
    iconClr: "colorE",
    icon: <IoMdAnalytics />,
    list: [
      { id: 1, li: "Call and Text Tracking" },
      { id: 2, li: "Customizable Dashboards" },
      { id: 3, li: "System Integration" },
    ],
  },
  {
    id: 6,
    title: "AI",
    iconClr: "colorF",
    icon: <AiOutlineRobot />,
    list: [
      { id: 1, li: "Virtual Assistant" },
      { id: 2, li: "Opportunity Mining" },
      { id: 3, li: "Response" },
      { id: 4, li: "Appointment Setting" },
    ],
  },
  {
    id: 7,
    title: "Operational Support",
    iconClr: "colorG",
    icon: <FaChalkboardTeacher />,
    list: [
      { id: 1, li: "CRM & Process Training" },
      { id: 2, li: "Third-Party IT Management" },
      { id: 3, li: "Systems Integrations" },
    ],
  },
];
